import React, { useEffect } from 'react';
import SEO from '../components/seo';

const SeoData = {
  Title: "Download App | Sajdah - The World's First Smart Educational Prayer Rug",
  Keywords: [`Download`, `App`, `Sajdah`, `Prayer`, `Rug`, `Islam`, `Smart`],
  Description:
    "Download Sajdah app, the world's first smart educational prayer rug that teaches new Muslims and children the second most important pillar in Islam.",
  Image: 'https://getsajdah.com/sajdah_og_image_thumb.jpg',
  url: 'https://getsajdah.com/app',
  titleTemplate: "Download App | Sajdah - The World's First Smart Educational Prayer Rug"
};

const AppDownload = () => {
  function getOS() {
    if (typeof window === 'undefined' || typeof navigator === 'undefined') {
      return 'undefined';
    }

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  }

  const redirectOS = os => {
    if (os === 'Android') {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.getsajdah.sajdah.android';
    } else if (os === 'iOS') {
      window.location.href = 'https://apps.apple.com/us/app/sajdah-prayer-guide-times/id1557291793';
    } else {
      window.location.href = '/';
    }
  };

  useEffect(() => {
    const os = getOS();
    redirectOS(os);
  });

  return (
    <SEO
      description={SeoData.Description}
      keywords={SeoData.Keywords}
      title={SeoData.Title}
      image={SeoData.Image}
      url={SeoData.url}
      titleTemplate={SeoData.titleTemplate}
    />
  );
};

export default AppDownload;
